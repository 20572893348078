import deephowApiServerWrapper from "@/server/api-server";
import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";
import { analyticsApiWrapperGenerator } from "./lib";

function getBaseUrl() {
  return environment.backendType === "firebase"
    ? environment.service[environment.env].analytics
    : environment.service[environment.env]["china"].analytics;
}

const analyticsServer = axios.create({
  baseURL: `${getBaseUrl()}v1`,
});

analyticsServer.interceptors.request.use(
  async (config) => {
    config.headers["x-user-token"] = await authChoice().currentUser.getIdToken();
    return config;
  },
  (error) => Promise.resolve(error)
);

const apiWrapper = analyticsApiWrapperGenerator(analyticsServer);

export const fetchWorkspaceStates = ({ page = 1, size = 500, workspaces }) =>
  apiWrapper("post", `workspaces/list?page=${page}&size=${size}`, { workspaces });

export const getMetabaseUrl = (params) => apiWrapper("post", "metabase/embedded-url", params);

export const getSkillPlaylistProgress = (params) => apiWrapper("post", "skills/assigned", params);

export const getSkillWorkflowProgress = ({ playlistId, ...params }) =>
  apiWrapper("post", `skills/${playlistId}/parts`, params);

export const getManySkillPlaylistProgress = (params) => apiWrapper("post", `skills/users-progress`, params);

export const getGroupUserStats = ({ groupId }) => apiWrapper("post", `workspaces/${groupId}/users`);

export const getOneWorkflowStats = ({ workflowId }) => apiWrapper("post", `workflows/${workflowId}/users`);

export const getOneWorkflowOverview = ({ workflowId }) => apiWrapper("post", `workflows/${workflowId}/overview`);

export const getOneUserOverview = ({ uid }) => apiWrapper("post", `users/${uid}/overview`);

export const getTopGroups = () => apiWrapper("post", "workspaces/top");

export const getUserOverview = (params) => apiWrapper("post", "users/overview", params);

export const getUserStats = ({ page = 1, size = 500, users }) =>
  apiWrapper("post", `users/list?page=${page}&size=${size}`, { users });

export const getUserList = ({ apiQueryPage, ...params }) =>
  apiWrapper("post", `users/list?page=${apiQueryPage}&size=100`, params);

export const getTopUsers = () => apiWrapper("post", "users/top");

export const getWorkflowOverview = (params) => apiWrapper("post", "workflows/overview", params);

export const getWorkflowStats = ({ page = 1, size = 500, workflows }) =>
  apiWrapper("post", `workflows/list?page=${page}&size=${size}`, { workflows });

export default analyticsServer;

// migrate to use deephow api

export const getOrgOverview = () => deephowApiServerWrapper("get", "/v1/analytics/organizations/overview");

export const getOrgEngagement = () => deephowApiServerWrapper("get", "/v1/analytics/organizations/engagement");

export const getQuizOverview = (workspaceId = null) =>
  deephowApiServerWrapper("get", "/v1/analytics/quizzes/overview", { params: { workspaceId } });

export const getQuizWorkflows = (params) =>
  deephowApiServerWrapper("get", "/v1/analytics/quizzes/workflow-list", { params });

export const getQuizWorkflowOverview = (workflowId) =>
  deephowApiServerWrapper("get", `/v1/analytics/quizzes/${workflowId}/overview`);

export const getQuizWorkflowUsers = ({ quizId, ...params }) =>
  deephowApiServerWrapper("get", `/v1/analytics/quizzes/${quizId}/user-list`, { params });

export const getQuizWorkflowUser = ({ quizId, userId }) =>
  deephowApiServerWrapper("get", `/v1/analytics/quizzes/${quizId}/user/${userId}/result-history`);
