export default {
  mvpVue: { serviceName: "mvp-vue", serviceModule: "mvp-vue" },
  language: { serviceName: "language-server", serviceModule: "languages" },
  video: { serviceName: "video-server", serviceModule: "videos" },
  search: { serviceName: "text-search-server", serviceModule: "text-search" },
  crossModalitySearch: { serviceName: "cross-modality-search", serviceModule: "cross-modality-search" },
  admin: { serviceName: "admin-server", serviceModule: "admin" },
  sso: { serviceName: "admin-server", serviceModule: "sso" },
  sign: { serviceName: "sign-server", serviceModule: "sign" },
  qrcode: { serviceName: "qrcode-server", serviceModule: "qrcode" },
  analytics: { serviceName: "analytics-server", serviceModule: "analytics" },
  recommend3: { serviceName: "recommendation-api", serviceModule: "recommends" },
  dataExporter: { serviceName: "analytics-exporter-server", serviceModule: "analytics-exporter" },
  workflow: { serviceName: "workflow-server", serviceModule: "workflows" },
  deephowApiServer: { serviceName: "deephow-api", serviceModule: "deephow" },
  externalApi: { serviceName: "external-api", serviceModule: "integrations" },
  chatServer: { serviceName: "chat-api", serviceModule: "chat" },
  whisperServer: { serviceName: "whisper-api", serviceModule: "speech-to-text" },
  azureSpeechServer: { serviceName: "azure-speech-api", serviceModule: "text-to-speech" },
  visualizationServer: { serviceName: "visualization-api", serviceModule: "visualization" },
  ulApiServer: { serviceName: "ul-integration", serviceModule: "compliance-wire" },
  summarizationServer: { serviceName: "summarization-api", serviceModule: "summarization" },
};
