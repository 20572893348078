import environment from "@/../env";

const getServiceMap = () => {
  const isGlobal = environment.backendType === "firebase";
  const services = isGlobal ? environment.service[environment.env] : environment.service[environment.env]["china"];
  return {
    coreServices: services.deephowApiServer,
    assetServices: services.sign,
    adminServices: services.admin,
    analyticsServices: services.analytics,
    recommendationServices: services.recommend3,
    ...(isGlobal
      ? {
          mavenServices: services.chatServer,
        }
      : {
          databaseServices: services.databaseServices,
        }),
  };
};

export const CONNECTION_FAILED_TYPE = {
  CLOSE: "CLOSE",
  INIT_TO_CHECKED_DOMAIN: "INIT_TO_CHECKED_DOMAIN",
  LOGIN_EMAIL: "LOGIN_EMAIL",
  LOGIN_SSO: "LOGIN_SSO",
};

export const STATUS = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  LOADING: "LOADING",
};

export const STATUS_ICON_ATTRIBUTES = {
  [STATUS.SUCCESS]: {
    icon: ["fa", "circle-check"],
    class: "text-success",
  },
  [STATUS.FAILED]: {
    icon: ["fas", "circle-xmark"],
    class: "text-danger",
  },
  [STATUS.LOADING]: {
    icon: ["fas", "spinner"],
    class: "text-loading",
    spin: true,
  },
};

export const SERVICE_MAP = getServiceMap();

export const DEFAULT_SERVICE_STATUS = Object.keys(SERVICE_MAP).reduce(
  (result, key) => ({ ...result, [key]: STATUS.LOADING }),
  {}
);
